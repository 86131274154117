import _ from 'lodash';
import moment from 'moment';
import parser from 'ua-parser-js';
import { FACEBOOK_BROWSER, INSTAGRAM_BROWSER, TIKTOK_BROWSER } from '@app/src/constants/browserConstants';
import { Url_FACEBOOK_CLICK_ID_COOKIE_KEY } from '@app/src/constants/onboardingConstants';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import {
  COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A,
  COLLECTION_TYPE__CREDIT_HOMEOWNER,
  COLLECTION_TYPE__CREDIT_IRA,
  COLLECTION_TYPE__CREDIT_STUDENT_LOANS,
  COLLECTION_TYPE__CREDIT_STUDENT_TUITION
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_COLLECTION_TYPES } from '@app/src/taxflow/sections/income/incomeConstants';
import { SPECIAL_CHARS } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

/**
 * @desc Checks if given value is Number
 * @param {*} value // Accepts string
 */
export function isNumber(value) {
  var myRegEx = /^(\s*[0-9]+\s*)+$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

export function isFloat(value) {
  return /^\s*(\d+)(\.\d*)?\s*$/.test(value);
}

/**
 * @desc Checks for valid email
 * @param {*} value // Accepts string
 */
export function isEmail(value) {
  var myRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var isValid = myRegEx.test(value);
  return isValid ? true : false;
}

/**
 * @desc Checks for Empty string
 * @param {*} value // Accepts string, object
 */
export function isEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

/**
 * @desc check does it dev mode or live mode
 * it return false only if its a production build
 */
const isDev = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
};

/**
 * @desc get cookie
 */
export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * @desc delete cookie
 */
export const deleteCookie = (name, path = '/', domain = undefined) => {
  try {
    //set older time in cookie to expired its to remove/delete cookie
    createCookie(name, '', -365, path, domain);
  } catch (e) {}
};
/**
 * @desc create cookie
 */
export const createCookie = (name, value, days = 1, path = '/', domain = undefined) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  const newCookie = name + '=' + value + expires + (domain ? ';domain=' + domain : '') + ';path=' + path;
  if (isDev()) {
  }
  document.cookie = newCookie;
};
/**
 * @desc get domain for the cookie
 */
export const getDomain = () => {
  if (REACT_APP_ENV === 'production') {
    return 'keepertax.com';
  } else if (REACT_APP_ENV === 'staging') {
    return 'netlify.com';
  } else {
    return 'localhost';
  }
};

const coalesceNumber = (num) => (_.isNumber(num) ? num : 0);

export const currencyWith2DecimalPlaces = (num) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(coalesceNumber(num));
};

export const currencyWith0DecimalPlaces = (num) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
    coalesceNumber(num)
  );
};

export const numberWith0DecimalPlaces = (num) => {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(coalesceNumber(num));
};

export const formatPhoneNumber = (phoneNumberString) => {
  try {
    if (!phoneNumberString) return;
    phoneNumberString = phoneNumberString.toString();
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '1' : '';
      return [intlCode, '-', match[2], '-', match[3], '-', match[4]].join('');
    }
  } catch (e) {}
  return phoneNumberString;
};

export const formatPhoneNumberToDisplay = (phoneNumberString) => {
  try {
    if (!phoneNumberString) return;
    phoneNumberString = phoneNumberString.toString();
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ')-', match[3], '-', match[4]].join('');
    }
  } catch (e) {}
  return phoneNumberString;
};

export const sentMsgToReactNative = (type, data) => {
  if (isReactNative()) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: type, data: data }));
    } catch (e) {
      defaultCaptureException(e);
    }
  }
};

export const isReactNative = () => !!window.ReactNativeWebView;

export const getIsTaxFilingSandwiched = () => isReactNative() && !!window.isFilingSandwiched;

export const isMobileBrowser = () => {
  const ua = parser(window.navigator.userAgent);
  return ['mobile', 'tablet'].includes(_.get(ua, ['device', 'type']));
};

export const getBrowserOS = () => {
  const ua = parser(window.navigator.userAgent);
  return (_.get(ua, ['os', 'name']) || '').toLowerCase();
};

export const getBrowserName = () => {
  const ua = parser(window.navigator.userAgent);
  return (_.get(ua, ['browser', 'name']) || '').toLowerCase();
};

export const safeJsonParse = ({ jsonStr, defaultVal }) => {
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return defaultVal;
  }
};

// Facebook conversion tracking requires specific formatting with fbclid.
export const formatFacebookClickId = (facebookClickId) => {
  const cookie = getCookie(Url_FACEBOOK_CLICK_ID_COOKIE_KEY);
  if (/^fb/.test(cookie)) {
    return cookie;
  }

  if (facebookClickId) {
    // We should update '1' below to read value from referrer.
    // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
    return `fb.1.${Date.now()}.${facebookClickId}`;
  }

  return null;
};

/*  Removes extra whitespace, limits to one blank space in a row and trims ends
ex. ' f   ff ' -> 'f ff'
*/
export const removeExtraWhitespace = (string) => {
  return string.replace(/\s+/g, ' ').trim();
};

export const hasSpecialChars = (string, type) => {
  const patterns = {
    lettersOnly: /^[a-zA-Z]+$/,
    numbersOnly: /^\d+$/,
    lettersNumbers: /^[a-zA-Z\d]+$/,
    lettersSpacesHyphens: /^[a-zA-Z -]+$/,
    lettersNumbersSpacesHyphens: /^[a-zA-Z\d -]+$/,
    lettersNumbersSpacesHyphensSlashes: /^[a-zA-Z\d /-]+$/
  };
  const pattern = patterns[type];
  if (!pattern) {
    return false;
  }

  if (pattern.test(string)) {
    return false;
  }

  return true;
};

export const hasQuestionSpecialChars = (string, question) => {
  // keeping for backwards compatibility
  const specialChars = new Set(SPECIAL_CHARS.split(''));
  const specialCharsAllowed = _.get(question, ['question_meta', 'allowSpecialChars'], true);
  if (!specialCharsAllowed && specialChars.has(string)) {
    return true;
  }

  const specialCharType = _.get(question, ['question_meta', 'specialCharType']);
  return hasSpecialChars(string, specialCharType);
};

export const getCurrentYear = () => `${moment().year()}`;

export const getPreviousYear = () => `${moment().year() - 1}`;

export const phoneNumberFormatHelper = (value) => {
  if (!value) {
    return value;
  }

  return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
};

export const replaceString = (string, replacements) => {
  if (!_.get(string, 'replace')) {
    return string;
  }

  return string.replace(/{([^{}]*)}/g, (_match, variableName) => _.get(replacements, variableName));
};

export const splitName = (fullName) => {
  const [firstname, ...lastnameArray] = removeExtraWhitespace(fullName).split(' ');
  const lastname = lastnameArray.join(' ');

  return [firstname, lastname];
};

export const getFormNamePreface = (formType) => {
  return {
    nec: '1099-NEC',
    misc: '1099-MISC',
    k: '1099-K',
    ssa: 'SSA-1099',
    pension: '1099-R',
    [INCOME_COLLECTION_TYPES.W2]: 'W-2',
    [INCOME_COLLECTION_TYPES.W2G]: 'W-2G',
    [INCOME_COLLECTION_TYPES.INTEREST]: '1099-INT',
    [INCOME_COLLECTION_TYPES.INVEST]: '1099-B',
    [INCOME_COLLECTION_TYPES.DIV]: '1099-DIV',
    [INCOME_COLLECTION_TYPES.G1099]: '1099-G',
    [COLLECTION_TYPE__CREDIT_HOMEOWNER]: '1098',
    [COLLECTION_TYPE__CREDIT_STUDENT_TUITION]: '1098-T',
    [COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A]: '1095-A',
    [COLLECTION_TYPE__CREDIT_IRA]: 'IRA',
    [COLLECTION_TYPE__CREDIT_STUDENT_LOANS]: '1098-E'
  }[formType];
};

export const convertEmpty = (value) => {
  if (_.isEmpty(value)) {
    return null;
  }

  return value;
};

export const isAppToAppRedirectBrowser = () => {
  return FACEBOOK_BROWSER || INSTAGRAM_BROWSER || TIKTOK_BROWSER;
};

export const toTitleCase = (str) => {
  return _.startCase(_.toLower(str));
};

export const getAttachmentType = (fileType) => {
  if (fileType.startsWith('image')) {
    return 'file/image';
  }

  switch (fileType) {
    case 'application/pdf':
      return 'file/pdf';
    case 'text/csv':
      return 'file/csv';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file/xlsx';
    default:
      throw new Error('Unsupported file type');
  }
};

/**
 * Used to capitalize sentences in the given string.
 */
export const capitalizeSentences = (str) => {
  // First, split by looking for hyperlinks
  const hyperlinkRegex = /(!\[.*\]\(.*\))/g;
  const hyperlinkSplits = str.split(hyperlinkRegex);

  // Then, split by punctuation marks
  const punctuationRegex = /([!?.] )/g;
  const sentences = hyperlinkSplits
    .map((hs) => {
      // If this string is a hyperlink, simply return it in a 1-length array
      if (hs.match(hyperlinkRegex)) {
        return [hs];
      }
      // Else, split by punctuation marks and capitalize the first letter of each sentence
      return hs.split(punctuationRegex).map((s) => {
        const index = s.search(/[a-zA-Z]/);
        if (index !== -1) {
          return s.substring(0, index) + s[index].toUpperCase() + s.substring(index + 1);
        }
        return s;
      });
    })
    .map((hs) => hs.join(''));

  // Finally, join the sentences with spaces
  return sentences.join(' ');
};
