// path components
export const PATH_COMPONENT__CREDIT_STANDARD_DEDUCTION = 'credit-standard-deduction';
export const PATH_COMPONENT__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE = 'credit-standard-household-incomplete';
export const PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED = 'credit-standard-itemized';
export const PATH_COMPONENT__CREDIT_STANDARD_RESULT = 'credit-standard-result';
export const PATH_COMPONENT__CREDIT_STANDARD_ITEMIZED_SUCCESS = 'credit-standard-itemized-success';
export const PATH_COMPONENT__CREDIT_EARNED_INCOME_DONE = 'credit-earned-income-done';
export const PATH_COMPONENT__CREDIT_QUARTERLY_TOTAL = 'credit-quarterly-total';
export const PATH_COMPONENT__CREDIT_HEALTH_OPTIONS = 'credit-health-options';
export const PATH_COMPONENT__CREDIT_HEALTH_EMPLOYER = 'credit-health-employer';
export const PATH_COMPONENT__CREDIT_HEALTH_FORM_UPLOAD = 'credit-health-form-upload';
export const PATH_COMPONENT__CREDIT_HEALTH_PLAN = 'credit-health-plan';
export const PATH_COMPONENT__CREDIT_HEALTH_ELIGIBLE = 'credit-health-eligible';
export const PATH_COMPONENT__CREDIT_HEALTH_RECEIVED = 'credit-health-received';
export const PATH_COMPONENT__CREDIT_HEALTH_SELF = 'credit-health-self';
export const PATH_COMPONENT__CREDIT_HEALTH_SELF_TOTAL = 'credit-health-self-total';
export const PATH_COMPONENT__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL = 'credit-healthcare-1095a-detail';
export const PATH_COMPONENT__CREDIT_HSA_DETAIL = 'credit-hsa-detail';
export const PATH_COMPONENT__CREDIT_HSA_DETAIL_FAMILY = 'credit-hsa-detail-family';
export const PATH_COMPONENT__CREDIT_CAPITAL_LOSS_INFO = 'credit-capital-loss-info';
export const PATH_COMPONENT__CREDIT_RETIREMENT_ACCOUNT_DETAIL = 'credit-retirement-account-detail';
export const PATH_COMPONENT__CREDIT_STUDENT_LOAN_DETAIL = 'credit-student-loan-detail';
export const PATH_COMPONENT__CREDIT_STUDENT_TUITION_QUALIFY = 'credit-student-tuition-qualify';
export const PATH_COMPONENT__CREDIT_STUDENT_TUITION_AOTC_QUALIFY = 'credit-student-tuition-aotc-qualify';
export const PATH_COMPONENT__CREDIT_STUDENT_TUITION_DETAIL = 'credit-student-tuition-detail';
export const PATH_COMPONENT__CREDIT_STUDENT_TUITION_FORM_UPLOAD = 'credit-student-tuition-form-upload';
export const PATH_COMPONENT__CREDIT_STUDENT_TUITION_PREFILL_LOADING = 'credit-student-tuition-prefill-loading';
export const PATH_COMPONENT__CREDIT_CHARITY_AMOUNT = 'credit-charity-amount';
export const PATH_COMPONENT__CREDIT_CHILD_CARE_DETAIL = 'credit-child-care-detail';
export const PATH_COMPONENT__CREDIT_COGS_INTRO = 'credit-cogs-intro';
export const PATH_COMPONENT__CREDIT_COGS_JOBS = 'credit-cogs-jobs';
export const PATH_COMPONENT__CREDIT_COGS_START_INVENTORY = 'credit-cogs-start-inventory';
export const PATH_COMPONENT__CREDIT_COGS_TOTAL_EXPENSES = 'credit-cogs-total-expenses';
export const PATH_COMPONENT__CREDIT_COGS_END_INVENTORY = 'credit-cogs-end-inventory';
export const PATH_COMPONENT__CREDIT_HOMEOWNER_FORM_UPLOAD = 'credit-homeowner-form-upload';
export const PATH_COMPONENT__CREDIT_HOMEOWNER_PREFILL_LOADING = 'credit-homeowner-prefill-loading';
export const PATH_COMPONENT__CREDIT_HOMEOWNER_DETAIL = 'credit-homeowner-detail';
export const PATH_COMPONENT__CREDIT_HOMEOWNER_ITEMIZED = 'credit-homeowner-itemized';
export const PATH_COMPONENT__CREDIT_CHILD_TAX_DETAIL = 'credit-child-tax-detail';
export const PATH_COMPONENT__CREDIT_ENERGY_INFO = 'credit-energy-info';
export const PATH_COMPONENT__CREDIT_TEACHING_INFO = 'credit-teaching-info';
export const PATH_COMPONENT__CREDIT_DISALLOWED = 'credit-disallowed';
export const PATH_COMPONENT__CREDIT_QBI = 'credit-qbi';
export const PATH_COMPONENT__CREDIT_DISALLOWED_NAMES = 'credit-disallowed-names';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTRO = 'credit-business-loans-intro';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_KNOWLEDGE = 'credit-business-loans-knowledge';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_INTEREST = 'credit-business-loans-interest';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_AMOUNT = 'credit-business-loans-amount';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_PERCENT = 'credit-business-loans-percent';
export const PATH_COMPONENT__CREDIT_BUSINESS_LOANS_CONFIRM = 'credit-business-loans-confirm';
export const PATH_COMPONENT__CREDIT_SAVERS_INTRO = 'credit-savers-intro';
export const PATH_COMPONENT__CREDIT_SAVERS_INFO = 'credit-savers-info';
export const PATH_COMPONENT__CREDIT_SAVERS_WITHDRAWAL = 'credit-savers-withdrawal';

// slugs
export const SLUG__CREDIT_STANDARD_DEDUCTION = 'credit-standard-deduction';
export const SLUG__CREDIT_STANDARD_HOUSEHOLD_INCOMPLETE = 'credit-standard-household-incomplete';
export const SLUG__CREDIT_STANDARD_ITEMIZED = 'credit-standard-itemized';
export const SLUG__CREDIT_STANDARD_MORTGAGE_INTEREST = 'credit-standard-mortgage-interest';
export const SLUG__CREDIT_STANDARD_CHARITY_TRACKED = 'credit-standard-charity-tracked';
export const SLUG__CREDIT_STANDARD_CHARITY_UNTRACKED = 'credit-standard-charity-untracked';
export const SLUG__CREDIT_STANDARD_RESULT = 'credit-standard-result';
export const SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS = 'credit-standard-itemized-success';
export const SLUG__CREDIT_EARNED_INCOME_DONE = 'credit-earned-income-done';
export const SLUG__CREDIT_QUARTERLY_TOTAL = 'credit-quarterly-total';
export const SLUG__CREDIT_HEALTH_OPTIONS = 'credit-health-options';
export const SLUG__CREDIT_HEALTH_EMPLOYER = 'credit-health-employer';
export const SLUG__CREDIT_HEALTH_PLAN = 'credit-health-plan';
export const SLUG__CREDIT_HEALTH_ELIGIBLE = 'credit-health-eligible';
export const SLUG__CREDIT_HEALTH_RECEIVED = 'credit-health-received';
export const SLUG__CREDIT_HEALTH_SELF = 'credit-health-self';
export const SLUG__CREDIT_HEALTH_SELF_TOTAL = 'credit-health-self-total';
export const SLUG__CREDIT_HEALTH_HAS_1095A = 'credit-healthcare-has-1095a';
export const SLUG__CREDIT_HEALTH_PLAN_FAMILY_MEMBERS = 'credit-health-plan-family-members';
export const SLUG__CREDIT_HEALTH_PLAN_START_DATE = 'credit-health-plan-policy-start-date';
export const SLUG__CREDIT_HEALTH_PLAN_END_DATE = 'credit-health-plan-policy-end-date';
export const SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL = 'credit-healthcare-1095a-detail';
export const SLUG__CREDIT_HSA_DETAIL = 'credit-hsa-detail';
export const SLUG__CREDIT_HSA_DETAIL_FAMILY = 'credit-hsa-detail-family';
export const SLUG__CREDIT_CAPITAL_LOSS_INFO = 'capital-loss-new-info';
export const SLUG__CREDIT_RETIREMENT_ACCOUNT_DETAIL = 'credit-retirement-account-detail';
export const SLUG__CREDIT_STUDENT_LOAN_DETAIL = 'credit-student-loan-detail';
export const SLUG__CREDIT_STUDENT_TUITION_QUALIFY = 'credit-student-tuition-qualify';
export const SLUG__CREDIT_STUDENT_TUITION_AOTC_QUALIFY = 'credit-student-tuition-aotc-qualify';
export const SLUG__CREDIT_STUDENT_TUITION_DETAIL = 'credit-student-tuition-detail';
export const SLUG__CREDIT_STUDENT_TUITION_WHO = 'credit-student-tuition-who';
export const SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD = 'credit-student-tuition-form-upload';
export const SLUG__CREDIT_STUDENT_TUITION_PREFILL_LOADING = 'credit-student-tuition-prefill-loading';
export const SLUG__CREDIT_CHILD_CARE_DETAIL = 'credit-child-care-detail';
export const SLUG__CREDIT_CHILD_CARE_WHO = 'credit-child-care-who';
export const SLUG__CREDIT_CHILD_CARE_FEDERAL_ID = 'credit-child-care-federal-id';
export const SLUG__CREDIT_CHARITY_AMOUNT = 'credit-charity-amount';
export const SLUG__CREDIT_COGS_INTRO = 'credit-cogs-intro';
export const SLUG__CREDIT_COGS_JOBS = 'credit-cogs-jobs';
export const SLUG__CREDIT_COGS_START_INVENTORY = 'credit-cogs-start-inventory';
export const SLUG__CREDIT_COGS_TOTAL_EXPENSES = 'credit-cogs-total-expenses';
export const SLUG__CREDIT_COGS_END_INVENTORY = 'credit-cogs-end-inventory';
export const SLUG__CREDIT_CHILD_CARE_STREET = 'credit-child-care-street';
export const SLUG__CREDIT_CHILD_CARE_APT = 'credit-child-care-apt';
export const SLUG__CREDIT_CHILD_CARE_CITY = 'credit-child-care-city';
export const SLUG__CREDIT_CHILD_CARE_STATE = 'credit-child-care-state';
export const SLUG__CREDIT_CHILD_CARE_ZIP = 'credit-child-care-zip';
export const SLUG__CREDIT_CHILD_CARE_PHONE = 'credit-child-care-phone';
export const SLUG__CREDIT_STUDENT_TUITION_STREET = 'credit-student-tuition-street';
export const SLUG__CREDIT_STUDENT_TUITION_CITY = 'credit-student-tuition-city';
export const SLUG__CREDIT_STUDENT_TUITION_STATE = 'credit-student-tuition-state';
export const SLUG__CREDIT_STUDENT_TUITION_ZIP = 'credit-student-tuition-zip';
export const SLUG__CREDIT_HOMEOWNER_FORM_UPLOAD = 'credit-homeowner-form-upload';
export const SLUG__CREDIT_HOMEOWNER_PREFILL_LOADING = 'credit-homeowner-prefill-loading';
export const SLUG__CREDIT_HOMEOWNER_DETAIL = 'credit-homeowner-detail';
export const SLUG__CREDIT_HOMEOWNER_INTEREST = 'credit-homeowner-interest';
export const SLUG__CREDIT_HOMEOWNER_SECURING_SAME = 'credit-homeowner-securing-same';
export const SLUG__CREDIT_HOMEOWNER_SECURING_HEADER = 'credit-homeowner-securing-header';
export const SLUG__CREDIT_HOMEOWNER_SECURING_STREET = 'credit-homeowner-securing-street';
export const SLUG__CREDIT_HOMEOWNER_SECURING_CITY = 'credit-homeowner-securing-city';
export const SLUG__CREDIT_HOMEOWNER_SECURING_STATE = 'credit-homeowner-securing-state';
export const SLUG__CREDIT_HOMEOWNER_SECURING_ZIP = 'credit-homeowner-securing-zip';
export const SLUG__CREDIT_HOMEOWNER_ITEMIZED = 'credit-homeowner-itemized';
export const SLUG__CREDIT_HOMEOWNER_LENDER_STREET = 'credit-homeowner-lender-street';
export const SLUG__CREDIT_HOMEOWNER_LENDER_CITY = 'credit-homeowner-lender-city';
export const SLUG__CREDIT_HOMEOWNER_LENDER_STATE = 'credit-homeowner-lender-state';
export const SLUG__CREDIT_HOMEOWNER_LENDER_ZIP = 'credit-homeowner-lender-zip';
export const SLUG__CREDIT_CHILD_TAX_DETAIL = 'credit-child-tax-detail';
export const SLUG__CREDIT_ENERGY_INFO = 'credit-energy-info';
export const SLUG__CREDIT_TEACHING_INFO = 'credit-teaching-info';
export const SLUG__CREDIT_TEACHING_AMOUNT_SPOUSE = 'credit-teaching-amount-spouse';
export const SLUG__CREDIT_DISALLOWED = 'credit-disallowed';
export const SLUG__CREDIT_DISALLOWED_NAMES = 'credit-disallowed-names';
export const SLUG__CREDIT_QBI = 'credit-qbi';
export const SLUG__CREDIT_BUSINESS_LOANS_INTRO = 'credit-business-loans-intro';
export const SLUG__CREDIT_BUSINESS_LOANS_KNOWLEDGE = 'credit-business-loans-knowledge';
export const SLUG__CREDIT_BUSINESS_LOANS_INTEREST = 'credit-business-loans-interest';
export const SLUG__CREDIT_BUSINESS_LOANS_AMOUNT = 'credit-business-loans-amount';
export const SLUG__CREDIT_BUSINESS_LOANS_PERCENT = 'credit-business-loans-percent';
export const SLUG__CREDIT_BUSINESS_LOANS_CONFIRM = 'credit-business-loans-confirm';
export const SLUG__CREDIT_SAVERS_INTRO = 'credit-savers-intro';
export const SLUG__CREDIT_SAVERS_INFO = 'credit-savers-info';
export const SLUG__CREDIT_SAVERS_WITHDRAWAL = 'credit-savers-withdrawal';

// endpoint attributes
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_DEDUCTION = 'credit-standard-deduction';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_HEALTH_INSURANCE = 'credit-standard-health-insurance';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MEDICAL = 'credit-standard-medical';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY_TRACKED = 'credit-standard-charity-tracked';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY_UNTRACKED = 'credit-standard-charity-untracked';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_MORTGAGE_INTEREST = 'credit-standard-mortgage-interest';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_STATE_REAL_ESTATE = 'credit-standard-state-real-estate';
export const ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_GAMBLING_LOSSES = 'credit-standard-gambling-losses';
export const ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS = 'credit-healthcare-options';
export const ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_HAS_1095A = 'credit-healthcare-has-1095a';
export const ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_1095A_COLLECTION_TYPE_STARTED =
  'credit-healthcare-1095a-collection-type-started';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_COLLECTION_TYPE_STARTED = 'credit-charity-collection-type-started';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_STARTED = 'credit-charity-started';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY = 'credit-charity';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_TRACKED = 'credit-charity-tracked';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_UNTRACKED = 'credit-charity-untracked';
export const ENDPOINT_ATTRIBUTE__CREDIT_CHARITY_DONE = 'credit-charity-done';
export const ENDPOINT_ATTRIBUTE__CREDIT_HOMEOWNER_INTEREST = 'credit-homeowner-interest';
// collection types
export const COLLECTION_TYPE__CREDIT_STANDARD = 'credit-standard';
export const COLLECTION_TYPE__CREDIT_HEALTHCARE = 'credit-healthcare';
export const COLLECTION_TYPE__CREDIT_HEALTHCARE_1095A = 'credit-healthcare-1095a';
export const COLLECTION_TYPE__CREDIT_IRA = 'credit-ira';
export const COLLECTION_TYPE__CREDIT_STUDENT_LOANS = 'credit-student-loans';
export const COLLECTION_TYPE__CREDIT_STUDENT_TUITION = 'credit-student-tuition';
export const COLLECTION_TYPE__CREDIT_CHARITY = 'credit-charity';
export const COLLECTION_TYPE__CREDIT_HOMEOWNER = 'credit-homeowner';
export const COLLECTION_TYPE__CREDIT_DISALLOWED = 'credit-disallowed';

export const ITEMIZED_DEDUCTION_DEFAULTS_SLUGS = [
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_HEALTHCARE_1095A_PLAN_DETAIL
];
